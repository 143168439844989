import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art28_image from "../../../static/img/art28.jpg";

const Blog28 = () => {
  return (    
      <div className="container ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            Let’s talk about women’s health and wellbeing
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 28 January 2025</small>
          </p>
          <img src={art28_image} className="agtech" alt="..." />
          <p align="center" style={{fontSize: '0.8em'}}>Source: 
            &nbsp;<a href="https://www.freemalaysiatoday.com/category/leisure/health/2021/06/18/us-study-highlights-gender-gap-in-health-innovations/" target="_blank" rel="noreferrer">
            https://www.freemalaysiatoday.com/category/leisure/health/2021/06/18/us-study-highlights-gender-gap-in-health-innovations/</a></p>
          <p></p>          
          <p>
            The theme of sex and gender inequalities in the healthcare sector is as debated today as it was in the 90’s. Until 1993, few women were included in 
            clinical trials and research across the globe, as women were either assimilated with men when it came to health or they were simply not taken into 
            consideration concerning body differences or side effects [1]. In 1986, after several protests regarding the inclusion of women in health trials, 
            NIH (National Institutes of Health) established a policy that encouraged researchers to include women in studies. But it wasn’t until 1993 that 
            this ‘encouragement’ turned into law [2].
          </p>                                               
          <p>
            In spite of this, the gender gap in healthcare persists, with many types of medications, medical devices and interventions not properly tested on 
            the female body. According to a 2020 study, women are nearly twice more likely than men to experience an adverse drug reaction (ADRs). The authors 
            highlight the fact that most drugs currently in use are approved based on clinical trials conducted on men, which may determine an overmedication 
            when it comes to women [3].
          </p>
          <p>
            On a similar theme, a 2019 study of almost 7 million people in Denmark found that women were diagnosed when they were four years older than men 
            - for hundreds of different health conditions. Diagnoses for diabetes came four and a half years later, while cancer was diagnosed in women when 
            they were an average two and a half years older [4]. This is due to various reasons, the most important being the way the male and female bodies 
            are perceived. The males are the ‘norm’, widely researched in all health aspects, while the females are ‘atypical’, a lot of their symptoms and 
            reactions very different from the ‘norm’. A particularly good example is the stroke, where women experience several ‘non-traditional’ symptoms 
            which can put their lives at great risk if not interpreted correctly. These symptoms can be: sudden fatigue or weakness, nausea or vomiting, 
            shortness of breath or difficulty breathing and chest pain or discomfort. In the EU, cardiovascular diseases are the leading cause of death in women.
          </p>
          <p>
            Regarding old age, a 2020 report of the European Institute of Women’s Health affirms that, even though women in Europe have a higher life expectancy 
            than men (5.5 years), they live more years in poor health: 19.5 years compared with 14.5 years for men. This fact not only reduces quality of life 
            but also has implications for the sustainability of healthcare systems and services as well as financial and social structures [5]. Old age brigs a 
            variety of physical, mental and social problems for seniors, some of them requiring constant help. In this respect, life partners usually provide 
            the necessary care, until they are no longer able to, or they pass away. Widowhood is linked to poor health, both physically and psychologically [6].
          </p>
          <p>
            There do not seem to be gender differences in the mental health consequences of widowhood, both men and women experiencing it as an extremely high 
            stressor often leading to depression [6]. Even taking this into consideration, the number of widowed women in Europe is four times the number of men, 
            making them distinctly disadvantaged in that they are more likely to become widowed at an earlier stage of the life course and to remain widowed for a 
            longer period of time [7]. Early widowhood is associated with poor psychological well-being that does not seem to improve over time. Despite this, there 
            are few services that elderly women can access in order to improve their mental wellbeing.
          </p>
          <p>
            In conclusion, the gender health gap is a real issue for women of all ages. Due to the different ways in which the female body works and the insufficient 
            resources and funding to cater to the unique and diverse needs of women, female health and wellbeing is at risk. The subjects presented above make up for 
            a small part of the gender disparities found in healthcare, further research being needed for the readers interested in this issue. Nevertheless, we can 
            conclude that there needs to be more funding for research to understand women’s health issues and how different illnesses affect women’s health specifically.
          </p>
          <p></p>
          <div id="article-references">            
            <h5><i>References</i></h5>            
              <ol>
                <li>
                  Balch, B. et al. (2024) Why we know so little about women’s health, AAMC. Available at: &nbsp;
                  <a href="https://www.aamc.org/news/why-we-know-so-little-about-women-s-health" target="_blank" rel="noreferrer">
                    https://www.aamc.org/news/why-we-know-so-little-about-women-s-health
                  </a> (Accessed: 23 January 2025).
                </li>
                <li>
                  National Institutes of Health. History of women’s participation in clinical research. Available at: &nbsp;
                  <a href="https://orwh.od.nih.gov/toolkit/recruitment/history" target="_blank" rel="noreferrer">
                  https://orwh.od.nih.gov/toolkit/recruitment/history 
                  </a> (Accessed: 23 January 2025).
                </li>
                <li>
                  Zucker, I., Prendergast, B.J. (2020). Sex differences in pharmacokinetics predict adverse drug reactions in women. Biol Sex Differ. 11(1):32. 
                  doi: 10.1186/s13293-020-00308-5.
                </li>
                <li>
                  Westergaard, D., Moseley, P., Sørup, F.K.H. et al (2019). Population-wide analysis of differences in disease progression patterns in men and women. 
                  Nat Commun 10, 666. doi: 10.1038/s41467-019-08475-9.
                </li>
                <li>
                  Eurohealth.ie (2020). Women in Europe: Toward Healthy Ageing. Midlife and Older Women’s Health Report. Available at: &nbsp;
                  <a href="https://eurohealth.ie/wp-content/uploads/2022/05/MidlifeOlderwomensHealth-Interactive.pdf" target="_blank" rel="noreferrer">
                  https://eurohealth.ie/wp-content/uploads/2022/05/MidlifeOlderwomensHealth-Interactive.pdf 
                  </a> (Accessed: 22 January 2025).
                </li>
                <li>
                  Schmitz, A. (2020). Gendered experiences of widowhood and depression across Europe. Journal of Affective Disorders. doi: 10.1016/j.jad.2020.11.091.
                </li>
                <li>
                  Sasson, I., & Umberson, D. J. (2013). Widowhood and Depression: New Light on Gender Differences, Selection, and Psychological Adjustment. The Journals of Gerontology Series B: Psychological Sciences and Social Sciences, 69B(1), 135–145. doi:10.1093/geronb/gbt058.
                </li>
              </ol>
            </div>
          <p></p>
        </div>           
        
        <script>window.scrollTo(0,0);</script>
      </div>
            
  );
};

export default Blog28;

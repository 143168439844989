import "./Events.css";
const Events = () => {
  return (
    <div className="container events">
      <div className="row" id="main-title">
        <h1>Events</h1>
      </div>

      <div className="col">
        <h5>2025</h5>                    
      </div>
      <div className="col">
        <a href="https://www.medica-tradefair.com" target="_blank" rel="noreferrer">
          MEDICA 2025, Trade fair for medical technology & healthcare - 17-20 November 2025, Düsseldorf, Germany
        </a>
        <br />
        <a href="https://efmi.org/event/efmi-stc-2025-osnabruck-germany-20-22-oct-2025" target="_blank" rel="noreferrer">
          EFMI STC 2025, European Federation for Medical Informatics Conference - 20-22 Oct 2025, Osnabrück, Germany
        </a>
        <br />
        <a href="https://aging-geriatrics.org/#home" target="_blank" rel="noreferrer">
          7th World Congress On Aging and Geriatrics (WCAG-2025) - 22-23 Sep 2025, Lisbon, Portugal
        </a>
        <br />
        <a href="https://aaic.alz.org" target="_blank" rel="noreferrer">
          Alzheimer's Association International Conference - 27-31 July 2025, Toronto, Canada and Online
        </a>
        <br />
        <a href="https://www.icimth.com" target="_blank" rel="noreferrer">
          International Conference on Informatics Management and Technology in Healthcare (ICIMT 2025) - 4-6 July 2025, Athens, Greece
        </a>
        <br />        
        <a href="https://scisynopsisconferences.com/aging/about-us" target="_blank" rel="noreferrer">
          International Symposium on Aging (Aging 2025) - 12-13 June 2025, Rome, Italy
        </a>
        <br />
        <a href="https://chuv.aio-events.com/event/225" target="_blank" rel="noreferrer">
          19th International Conference On Clinical Ethics and Consultation - 4-7 June 2025, Lausanne, Switzerland
        </a>
        <br />
        <a href="https://mie2025.efmi.org" target="_blank" rel="noreferrer">
          35th Medical Informatics Europe Conference (MIE 2025), Intelligent health systems – From technology to data and knowledge, 19-21 May 2025 - Glasgow, Scotland
        </a>
        <br />
        <a href="https://eucas2025.unipv.it" target="_blank" rel="noreferrer">
          EUCAS 2025, Aging & Cognition Conference - 7-10 May 2025, Pavia, Italy
        </a>
        <br />
        <a href="https://aging.uniteexplores.com" target="_blank" rel="noreferrer">
          Unite Scientific Aging Conference (USAC-2025) - 28-29 April 2025, Vienna, Austria
        </a>
        <br />
        <a href="https://meetings.bna.org.uk/bna2025" target="_blank" rel="noreferrer">
          The International BNA 2025 Festival of Neuroscience - 27-30 April 2025, Liverpool, United Kingdom
        </a>
        <br />
        <a href="https://www.dmea.de/en" target="_blank" rel="noreferrer">
          DMEA 2025 - Connecting Digital Health, 8-10 April 2025, Berlin
        </a>
        <br />
        <a href="https://frailty-sarcopenia.com" target="_blank" rel="noreferrer">
          International Conference on Fraility and Sarcopenia (ICFSR 2025) - 12-14 March 2025, Toulouse, France
        </a>
        <br />
        <a href="https://dementiasconference.com/dementias2025/en/page/about-dementias-2025" target="_blank" rel="noreferrer">
          27th National Conference: Dementias 2025 - 6-7 February 2025, Minster Court Conference Centre, London, United Kingdom
        </a>
      </div>
      <br />


      <div className="col">
        <h5>2024</h5>                    
      </div>
      <div className="col">        
        <a href="https://waset.org/aging-and-cognition-conference-in-december-2024-in-rome" target="_blank" rel="noreferrer">
          International Conference on Aging and Cognition (ICACO) - 16-17 December 2024 Rome, Italy
        </a>
        <br />
        <a href="https://waset.org/cognitive-aging-and-neurophysiology-of-aging-conference-in-november-2024-in-venice" target="_blank" rel="noreferrer">
          International Conference on Cognitive Aging and Neurophysiology of Aging (ICCANA) - 11-12 November 2024, Venice, Italy
        </a>
        <br />
        <a href="https://waset.org/regular-exercise-and-aging-conference-in-november-2024-in-rome" target="_blank" rel="noreferrer">
          International Conference on Regular Exercise and Aging (ICREA) - 11-12 November 2024, Rome, Italy
        </a>
        <br />
        <a href="https://waset.org/aging-and-cognition-conference-in-september-2024-in-nice?utm_source=conferenceindex&utm_medium=referral&utm_campaign=listing" target="_blank" rel="noreferrer">
          International Conference on Aging and Cognition (ICACO) - 16-17 September 2024, Nice, France
        </a>
        <br />
        <a href="https://waset.org/advances-in-aging-research-conference-in-september-2024-in-amsterdam" target="_blank" rel="noreferrer">
          International Conference on Advances in Aging Research (ICAAR) - 12-13 September 2024, Amsterdam, Netherlands
        </a>
        <br />
        <a href="https://waset.org/exercise-in-aging-conference-in-august-2024-in-warsaw?utm_source=conferenceindex&utm_medium=referral&utm_campaign=listing" target="_blank" rel="noreferrer">
          International Conference on Exercise in Aging (ICEA) - 8-9 August 2024, Warsaw, Poland
        </a>
        <br />
        <a href="https://aging-geriatrics.com/?utm_source=conferenceindex&utm_medium=referral&utm_campaign=listing" target="_blank" rel="noreferrer">
          World Aging And Rejuvenation Conference (ARC) - 8-10 July 2024, Abbeville-Saint-Lucien, France
        </a>
        <br />
        <a href="https://waset.org/cognition-and-aging-conference-in-june-2024-in-london?utm_source=conferenceindex&utm_medium=referral&utm_campaign=listing" target="_blank" rel="noreferrer">
          International Conference on Cognition and Aging (ICCAG) - 27-28 June 2024, London, United Kingdom
        </a>
        <br />
        <a href="https://waset.org/exercise-and-aging-conference-in-may-2024-in-barcelona" target="_blank" rel="noreferrer">
          International Conference on Exercise and Aging (ICEA) - 23-24 May 2024, Barcelona, Spain
        </a>
        <br />
        <a href="https://waset.org/advances-in-aging-research-conference-in-may-2024-in-florence" target="_blank" rel="noreferrer">
           International Conference on Advances in Aging Research (ICAAR) - 16-17 May 2024, Florence, Italy
        </a>
        <br />
        <a href="https://waset.org/alzheimers-disease-and-aging-conference-in-april-2024-in-london" target="_blank" rel="noreferrer">
           International Conference on Alzheimer's Disease and Aging (ICADA) - 11-12 April 2024, London, United Kingdom
        </a>
      </div>
      <br />

      <div className="col">
        <h5>2023</h5>                    
      </div>
      <div className="col">              
        <a href="https://waset.org/regular-exercise-and-aging-conference-in-november-2023-in-rome?utm_source=conferenceindex&utm_medium=referral&utm_campaign=listing" target="_blank" rel="noreferrer">
          International Conference on Regular Exercise and Aging (ICREA) - 20-21 November 2023, Rome, Italy
        </a>
        <br />
        <a href="https://waset.org/cognitive-aging-and-neurophysiology-of-aging-conference-in-november-2023-in-venice?utm_source=conferenceindex&utm_medium=referral&utm_campaign=listing" target="_blank" rel="noreferrer">
          International Conference on Cognitive Aging and Neurophysiology of Aging (ICCANA) - 13-14 November 2023, Venice, Italy
        </a>
        <br />
        <a href="https://www.healthyageinguk.org/2023/" target="_blank" rel="noreferrer">
          Healthy Ageing 2023, London, 14 November 2023, United Kingdom
        </a>
        <br />
        <a href="https://globalageing2023.com/" target="_blank" rel="noreferrer">
          Global Ageing Conference, 6-8 September 2023, Glasgow, United Kingdom
        </a>
        <br />
        <a href="https://conferencealerts.com/show-event?id=248434" target="_blank" rel="noreferrer">
          World Aging And Rejuvenation Conference (ARC), 17-18 July 2023, Frankfurt, Germany
        </a>
        <br />
        <a href="https://waset.org/cognition-and-aging-conference-in-january-2023-in-paris?utm_source=conferenceindex&utm_medium=referral&utm_campaign=listing" target="_blank" rel="noreferrer">
          International Conference on Cognition and Aging (ICCAG), 23-24 January 2023, Paris, France
        </a>
      </div>
      <br />

      <div className="col">
        <h5>2022</h5>                    
      </div>
      <div className="col">
        <a href="https://www.facebook.com/stresscongress.aaif" target="_blank" rel="noreferrer">
          Stress Congress 2022, 6-7 October, Bucharest, Romania
        </a>
      </div>
      <br />
    </div>
  );
};

export default Events;
